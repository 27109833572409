<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-3">
                <h6 class="mb-0 font-bold">Bảng giá:</h6>
            </div>
            <div class="col-md-9 tab-price rounded">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            >Buff không bảo hành</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            >Buff bảo hành</a
                        >
                    </li>
                </ul>
                <div class="tab-content mt-3" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <template v-for="(pack, index) in packageEdit">
                            <div
                                v-show="!pack.package_name.includes('warranty')"
                                class="form-group"
                                :key="'pack' + index"
                            >
                                <!-- tool có speed -->
                                <div class="row flex-lg-column" v-if="pack.speed.length > 0">
                                    <div class="col w-100 d-flex align-items-center mb-3">
                                        <h6 class="mb-0 font-bold">{{ index + 1 }}. {{ pack.name }}</h6>
                                    </div>
                                    <div v-for="(speed, index) in pack.speed" :key="index" class="row mx-0 mt-lg-1">
                                        <div class="col col-lg d-flex align-items-center">
                                            <h6 class="mb-0 text-left">* Tốc độ: {{ speedName(speed.speed) }}</h6>
                                        </div>
                                        <div class="col-lg-4 px-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend text-left">
                                                    <span class="input-group-text px-0 tab-price"
                                                        >Giá site gốc:
                                                        <span class="bold ml-1">{{
                                                            speed.priceAdmin | formatNumber
                                                        }}</span></span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 pl-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text px-1 tab-price ml-1">Giá mới:</span>
                                                </div>
                                                <input
                                                    v-model.number="speed.priceEdit"
                                                    type="number"
                                                    class="form-control"
                                                    min="0"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- tool ko có speed -->
                                <div class="row" v-else>
                                    <div class="col-lg d-flex align-items-center">
                                        <h6 class="mb-0">{{ index + 1 }}. {{ pack.name }}</h6>
                                    </div>
                                    <div class="col-lg-4 px-0">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text px-0 tab-price"
                                                    >Giá site gốc:
                                                    <span class="bold ml-1">{{
                                                        pack.priceAdmin | formatNumber
                                                    }}</span></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 pl-0">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text px-1 tab-price ml-1">Giá mới:</span>
                                            </div>
                                            <input
                                                v-model.number="pack.priceEdit"
                                                type="number"
                                                class="form-control"
                                                min="0"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <template v-for="(pack, index) in packageEdit">
                            <div
                                v-show="pack.package_name.includes('warranty')"
                                class="form-group"
                                :key="'packageEdit' + index"
                            >
                                <div class="row">
                                    <div class="col-lg">
                                        <h6>{{ index + 1 }}. {{ pack.name }}</h6>
                                    </div>
                                    <div class="col-lg-4 px-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text px-0 tab-price"
                                                >Giá site gốc:
                                                <span class="bold ml-1">{{
                                                    pack.priceAdmin | formatNumber
                                                }}</span></span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 pl-0">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text px-1 tab-price">Giá mới:</span>
                                            </div>
                                            <input
                                                v-model.number="pack.priceEdit"
                                                type="number"
                                                class="form-control ml-1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right mt-3">
            <button @click="setPriceUpdate" class="mt-md-0 btn bold btn-dark-blue" type="submit">
                Lưu Bảng Giá
            </button>
        </div>
    </div>
</template>
<script>
import { priceUpdate } from "../../../api/admin"
export default {
    name: "site-price-detail",
    props: {
        selectedTool: Object,
        tools: Array
    },
    data() {
        return {
            packageEdit: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        packages() {
            return this.selectedTool && this.selectedTool.prices ? this.selectedTool.prices : []
        },
        packagesAdmin() {
            return this.selectedTool && this.selectedTool.prices_admin ? this.selectedTool.prices_admin : []
        },
        allSpeedObjects() {
            let results = []
            this.packageEdit.forEach(pk => {
                pk.speed.forEach(item => {
                    results.push(item)
                })
            })
            return results
        }
    },
    watch: {
        selectedTool(value) {
            if (value) {
                this.packageEdit = []
                this.packages.forEach(pack => {
                    let p = pack
                    let pAdmin = this.packagesAdmin.prices.find(item => item.package_name == p.package_name)
                    p.priceEdit = pack.price
                    p.priceAdmin = p.package_name.includes("config") ? pAdmin.price : pAdmin.price
                    if (p.speed && p.speed.length > 0) {
                        p.speed = p.speed.map(sp => {
                            let adminSpeed = pAdmin.speed.find(item => {
                                return item.speed == sp.speed
                            })
                            sp.name = p.name
                            sp.package_name_id = p.id
                            sp.package_name = p.package_name
                            sp.priceEdit = sp.price
                            sp.priceAdmin = adminSpeed.price || 0
                            return sp
                        })
                    }
                    this.packageEdit.push(p)
                })
            }
        }
    },
    methods: {
        speedName(speed) {
            switch (speed) {
                case "low":
                    return "Chậm"
                case "normal":
                    return "Trung bình"
                case "medium":
                    return "Thông thường"
                case "high":
                    return "Nhanh"
                default:
                    return ""
            }
        },
        getAllPackageObject() {
            // chỉ lọc ra những gói có giá edit !== giá gốc và giá edit >= 0
            const results = []
            const { packageEdit } = this
            packageEdit.forEach(pk => {
                if (!pk.speed.length) {
                    if (pk.price != pk.priceEdit && pk.priceEdit >= 0) {
                        pk.is_speed_package = false
                        results.push(pk)
                    }
                } else {
                    pk.speed.forEach(obj => {
                        if (obj.price != obj.priceEdit && obj.priceEdit >= 0) {
                            obj.is_speed_package = true
                            results.push(obj)
                        }
                    })
                }
            })
            return results
        },
        setPriceUpdate: async function() {
            const results = this.getAllPackageObject()
            if (results.length < 1) {
                this.$swal("Lỗi", "Vui lòng thay đổi giá trước khi tiến hành cập nhật", "error")
                return
            }
            let messages = ""
            for (let i = 0; i < results.length; i++) {
                const pack = results[i]
                const { is_speed_package } = pack
                let id = pack.id
                let postData = {
                    price: pack.priceEdit
                }
                if (is_speed_package) {
                    postData.speed = pack.speed
                    id = pack.price_id
                }
                let response = await priceUpdate(id, postData)
                if (response.status == 200 && response.success) {
                    const msg = `<div class="text-left font-14">${i + 1}. Giá gói: ${pack.name} ${
                        is_speed_package ? "- tốc độ: " + this.speedName(pack.speed) : ""
                    }: cập nhật thành công </div>`
                    messages += msg
                    // cập nhật UI
                    if (is_speed_package) {
                        this.packageEdit = this.packageEdit.map(pk => {
                            if (pk.id == id) {
                                pk.speed.forEach(obj => {
                                    if (obj.speed == pack.speed) {
                                        obj.price = pack.priceEdit
                                    }
                                })
                            }
                            return pk
                        })
                    } else {
                        this.packageEdit = this.packageEdit.map(pk => {
                            if (pk.id == id) {
                                pk.price = pack.priceEdit
                            }
                            return pk
                        })
                    }
                } else {
                    const msg = `<div class="text-left font-14">${i + 1}. Giá gói: ${pack.name} ${
                        is_speed_package ? "- tốc độ:" + this.speedName(pack.speed) : ""
                    }: ${response.message || "cập nhật thành công"} </div>`
                    messages += msg
                }
            }
            this.$swal.fire({
                icon: "info",
                title: "Kết quả",
                html: messages,
                showConfirmButton: true
            })
        }
    }
}
</script>
