<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Số điện thoại liên hệ:</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            placeholder="Nhập số điện thoại của bạn"
                            class="form-control input-light"
                            v-model="siteUpdate.mobile_phone"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Facebook liên hệ:</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            placeholder="Nhập Facebook của bạn"
                            class="form-control input-light"
                            v-model="siteUpdate.facebook"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Link Facebook chat:</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            placeholder="Nhập Facebook chat của bạn"
                            class="form-control input-light"
                            v-model="siteUpdate.config.fb_chat"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Link đến trang hướng dẫn:</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            placeholder="Nhập link trang hướng dẫn của bạn"
                            class="form-control input-light"
                            v-model="siteUpdate.config.guide"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Liên hệ hỗ trợ sẽ hiển thị như sau:</label>
                    <div class="col-sm-8">
                        <div class="card card-support my-3">
                            <div class="card-body pr-0">
                                <div class="row align-items-center">
                                    <div class="col-7">
                                        <h5 class="bold mb-0">LIÊN HỆ HỖ TRỢ</h5>
                                        <hr class="" />
                                        <a class="row align-items-center">
                                            <div class="col-auto">
                                                <img
                                                    class="group-icon block-text"
                                                    :src="'/assets/images/support/facebook.png'"
                                                    alt="group-icon"
                                                    width="33px"
                                                />
                                            </div>
                                            <div class="col block-text pl-0">
                                                <h5 class="block-text mb-0">{{ siteUpdate.facebook }}</h5>
                                            </div>
                                        </a>
                                        <a class="row align-items-center mt-3">
                                            <div class="col-auto">
                                                <img
                                                    class="group-icon block-text"
                                                    :src="'/assets/images/support/phone.png'"
                                                    alt="group-icon"
                                                    width="33px"
                                                />
                                            </div>
                                            <div class="col block-text pl-0">
                                                <h5 class="block-text mb-0">{{ siteUpdate.mobile_phone }}</h5>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-5 text-right">
                                        <img
                                            class="group-icon"
                                            :src="'/assets/images/support/support.png'"
                                            alt="group-icon"
                                            width="80%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    @click="updateSiteInfo(true)"
                                    class="btn-block btn-lg btn bold btn-red form-control mt-3"
                                    type="submit"
                                >
                                    Khôi phục cài đặt gốc
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    @click="updateSiteInfo()"
                                    class="btn form-control btn-lg bold btn-dark-blue form-control mt-3"
                                    type="submit"
                                >
                                    Lưu thông tin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-red mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn có thể chỉnh sửa các thông tin liên hệ của trang.
                            </h6>
                            <div class="bold">
                                Chọn khôi phục cài đặt gốc nếu bạn lưu thông tin thất bại!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "site-support",
    components: {},
    props: {
        levelList: Array,
        site: Object
    },
    data() {
        return {
            siteUpdate: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    watch: {
        site() {
            this.siteUpdate = Object.assign({}, this.site)
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
    },
    methods: {
        updateSiteInfo: async function(reset = false) {
            if (this.siteUpdate.config && this.siteUpdate.config.guide) {
                let text = this.siteUpdate.config.guide
                if (!text.includes("http://") && !text.includes("https://")) {
                    text = "http://" + text
                }
                this.siteUpdate.config.guide = text
            }
            let cancel = false
            if (reset) {
                await this.$swal({
                    title: "Xác nhận",
                    html:
                        "Sau khi khôi phục cài đặt bạn sẽ phải cài lại giao diện, nội dung chuyển khoản và Facebook hỗ trợ.</br></br>Bạn có chắc chắn muốn khôi phục cài đặt gốc?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Đồng Ý",
                    cancelButtonText: "Không",
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.siteUpdate.config = null
                    } else {
                        cancel = true
                    }
                })
            }
            if (cancel) {
                return
            }
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                let siteHost = document.location.host
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", siteHost)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
