<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label font-bold">API Key:</label>
                    <div class="col-sm-10">
                        <input
                            type="text"
                            placeholder="Nhập API Key của bạn"
                            class="form-control input-light"
                            v-model="apiKey"
                        />
                        <button @click="updateApiKey()" class="btn mt-4 form-control bold btn-dark-blue" type="submit">
                            Lưu API Key
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-red mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn cần cập nhật API key sau khi đổi mật khẩu bên Site gốc.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateUser } from "../../../api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "site-api-key",
    components: {},
    data() {
        return {
            apiKey: ""
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    methods: {
        updateApiKey: async function() {
            let postData = { api_key: this.apiKey }
            let userId = this.site.user_id
            let data = await updateUser(userId, postData)
            if (data.status === 200 && data.success) {
                this.$store.commit("APP_RELOAD")
                this.$swal("Thành Công", "Cập nhật Api Key thành công", "success")
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
