<template>
    <div>
        <h4 class="bold text-uppercase">Cài đặt trang</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('prices')"
                    id="price-tab"
                    data-toggle="tab"
                    href="#prices"
                    role="tab"
                    aria-controls="price"
                    aria-selected="true"
                    >Cài đặt bảng giá</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('target')"
                    id="target-tab"
                    data-toggle="tab"
                    href="#target"
                    role="tab"
                    aria-controls="web"
                    aria-selected="false"
                    >Cài đặt Lọc Nâng Cao</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('web')"
                    id="web-tab"
                    data-toggle="tab"
                    href="#web"
                    role="tab"
                    aria-controls="web"
                    aria-selected="false"
                    >Cài đặt Website</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('api')"
                    id="api-tab"
                    data-toggle="tab"
                    href="#api"
                    role="tab"
                    aria-controls="api"
                    aria-selected="false"
                    >Cài đặt API key</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('layout')"
                    id="layout-tab"
                    data-toggle="tab"
                    href="#layout"
                    role="tab"
                    aria-controls="layout"
                    aria-selected="false"
                    >Cài đặt giao diện</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('payment')"
                    id="payment-tab"
                    data-toggle="tab"
                    href="#payment"
                    role="tab"
                    aria-controls="payment"
                    aria-selected="false"
                    >Cài đặt thanh toán</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('support')"
                    id="support-tab"
                    data-toggle="tab"
                    href="#support"
                    role="tab"
                    aria-controls="support"
                    aria-selected="false"
                    >Cài đặt hỗ trợ</a
                >
            </li>
            <li class="nav-item" v-if="isDungQb">
                <a
                    class="nav-link"
                    @click="handleShowPanel('agency')"
                    id="agency-tab"
                    data-toggle="tab"
                    href="#agency"
                    role="tab"
                    aria-controls="agency"
                    aria-selected="false"
                    >Cài đặt đại lý</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="prices" role="tabpanel" aria-labelledby="price-tab">
                <siteprices :levelList="levelList"></siteprices>
            </div>
            <div class="tab-pane fade" id="target" role="tabpanel" aria-labelledby="target-tab">
                <sitetarget :site="site"></sitetarget>
            </div>
            <div class="tab-pane fade" id="web" role="tabpanel" aria-labelledby="web-tab">
                <sitewebsite :site="site"></sitewebsite>
            </div>
            <div class="tab-pane fade" id="api" role="tabpanel" aria-labelledby="api-tab">
                <siteapikey :site="site"></siteapikey>
            </div>
            <div class="tab-pane fade" id="layout" role="tabpanel" aria-labelledby="layout-tab">
                <sitelayout :site="site"></sitelayout>
            </div>
            <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                <sitepayment :site="site"></sitepayment>
            </div>
            <div class="tab-pane fade" id="support" role="tabpanel" aria-labelledby="support-tab">
                <sitesupport :site="site"></sitesupport>
            </div>
            <div class="tab-pane fade" id="agency" role="tabpanel" aria-labelledby="agency-tab">
                <siteagency :site="site"></siteagency>
            </div>
        </div>
    </div>
</template>
<script>
import { getListLevels } from "../../../api/admin"
// import { catchError, formatNumber } from "../../../helpers"
import siteprices from "./site-prices"
import sitetarget from "./site-target"
import sitewebsite from "./site-website"
import siteapikey from "./site-api-key"
import sitelayout from "./site-layout"
import sitepayment from "./site-payment"
import sitesupport from "./site-support"
import siteagency from "./site-agency"

export default {
    name: "site-manager",
    components: {
        siteprices,
        sitetarget,
        sitewebsite,
        siteapikey,
        sitelayout,
        sitepayment,
        sitesupport,
        siteagency
    },
    data() {
        return {
            userNameSearching: "",
            idSearching: "",
            limit: 100,
            panel: "list",
            data: [],
            data_success: [],
            levelList: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        isDungQb() {
            return true
        }
    },
    async created() {
        await this.getListLevels()
    },
    methods: {
        getListLevels: async function() {
            let data = await getListLevels()
            this.levelList = data.data
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
