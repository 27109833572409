<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <h4 class="bold mb-3">Kích hoạt tính năng nạp tiền tự động lên đại lý</h4>
                        <div class="form-group row align-items-center mb-0">
                            <label class="col-sm-3 col-12 col-form-label font-bold">Trạng thái kích hoạt:</label>
                            <div class="col-sm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button
                                            @click="siteUpdate.auto_up_level = !siteUpdate.auto_up_level"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                siteUpdate.auto_up_level ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Bật
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button
                                            @click="siteUpdate.auto_up_level = !siteUpdate.auto_up_level"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                !siteUpdate.auto_up_level ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Tắt
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="siteUpdate.auto_up_level" class="form-group row mb-0 mt-3">
                            <label class="col-sm-3 col-12 col-form-label font-bold"
                                >Số tiền tối thiểu nạp lên đại lý:</label
                            >
                            <div class="col-sm">
                                <input
                                    type="number"
                                    placeholder="Nhập số tiền tối thiểu nạp lên đại lý"
                                    class="form-control input-light"
                                    v-model="siteUpdate.total_up_level_agency"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-gray mt-3">
                    <div class="card-body">
                        <h4 class="bold mb-3">Hướng dẫn cho User kích hoạt Site đại lý</h4>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-bold">Link video hướng dẫn hướng dẫn:</label>
                            <div class="col-sm-8">
                                <input
                                    type="text"
                                    placeholder="Nhập link trang hướng dẫn của bạn"
                                    class="form-control input-light"
                                    v-model="siteUpdate.config.help_video_agency"
                                />
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-3 col-form-label font-bold">Nội dung hướng dẫn Active Site:</label>
                            <div class="col-sm-8">
                                <ckeditor
                                    :editor="editor"
                                    v-model="siteUpdate.config.help_content_agency"
                                    :config="editorConfig"
                                    @ready="onReady"
                                ></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="updateSiteInfo()" class="btn mt-4 form-control bold btn-dark-blue" type="submit">
                    Lưu Cài Đặt
                </button>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-red mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Tật tắt chức năng nạp tiền tự động lên đại lý, lưu ý phải nhập số tiền tối thiểu đã nạp
                                để khách hàng của bạn tự động lên đại lý.
                            </h6>
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Cài đặt hướng dẫn cho User của bạn biết cách tích hợp site đại lý.
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card card-info mt-3">
                    <div class="card-body">
                        <h6 class="font-bold text-center">VIDEO HƯỚNG DẪN</h6>
                        <iframe
                            class="mt-2"
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/npEX_FNdrPk"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin"
import { catchError } from "../../../helpers"
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
export default {
    name: "site-agency",
    props: {
        site: Object
    },
    data() {
        return {
            editor: DecoupledEditor,
            editorConfig: {
                placeholder: "Nhập nội dung hướng dẫn cho User",
                removePlugins: ["MediaEmbed", "ImageUpload", "Heading"]
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.siteUpdate.siteUpdate.currency || "Xu"
        }
    },
    watch: {
        site() {
            this.siteUpdate = this.site
        }
    },
    created() {
        this.siteUpdate = this.site
    },
    methods: {
        onReady(editor) {
            editor.ui
                .getEditableElement()
                .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement())
        },
        updateSiteInfo: async function() {
            if (this.siteUpdate.config && this.siteUpdate.config.help_video_agency) {
                this.siteUpdate.config.help_video_agency =
                    "https://www.youtube.com/embed/" + this.getIdVideo(this.siteUpdate.config.help_video_agency)
            }
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                let siteHost = document.location.host
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", siteHost)
            } else this.$swal("Lỗi", catchError(data), "error")
        },
        getIdVideo: function(url) {
            var result = null
            if (url.indexOf("youtube") < 0 && !url.includes("youtu.be")) {
                result = url
            } else {
                let postId = ""
                if (url.includes("watch")) {
                    postId = url.match(/(.*)\/watch\?v=(.*)?&/) || url.match(/(.*)\/watch\?v=(.*)?/)
                } else postId = url.match(/(.*)\/youtu\.be\/(.*)?&/) || url.match(/(.*)\/youtu\.be\/(.*)?/)
                if (postId) {
                    result = postId[2].split("?")[0]
                }
            }
            return result
        }
    }
}
</script>
