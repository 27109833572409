<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="row form-group align-items-center">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Lọc nâng cao:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="openTarget()"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                open_target ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Bật
                                        </button>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="openTarget()"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                !open_target ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Tắt
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="mb-0 mt-3 font-14 red">
                            Nếu bạn <span class="bold green">Bật</span> thì hệ thống sẽ mở lọc các Target:
                            <span class="bold green">Giới tính, độ tuổi, số bạn bè.</span>
                        </h6>
                        <div v-if="open_target">
                            <h6 class="mb-0 mt-3 font-14 red">
                                Hệ thống sẽ <span class="bold green">thu phí thêm {{ priceTargetLike }}%</span> cho
                                <span class="bold green">1 lựa chọn nâng cao Like, Comment, Share.</span>
                            </h6>
                            <h6 class="mb-0 mt-3 font-14 red">
                                Hệ thống sẽ <span class="bold green">thu phí thêm {{ priceTargetFollow }}%</span> cho
                                <span class="bold green">1 lựa chọn nâng cao Follow, Like Page.</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-red h-100">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Lưu ý: Bạn có thể bật tắt tính năng lọc nâng cao của Facebook tại đây.
                            </h6>
                            <h6>
                                Hãy Qua cài đặt giá và cài đặt Buff Theo Target % thật chính xác trước khi bật tính
                                năng.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "site-target",
    components: {},
    data() {
        return {
            apiKey: ""
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        open_target() {
            return this.$store.state.site.site.config && this.$store.state.site.site.config.open_target
                ? this.$store.state.site.site.config.open_target
                : false
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            return tool.prices
        },
        priceTargetLike() {
            let getPackage = "seeding_by_workers_query_config"
            if (this.type === "follow" || this.type === "like_page") {
                getPackage = "seeding_by_workers_query_" + this.type + "_config"
            }
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            let priceTarget = tool.prices.find(price => price.package_name.includes(getPackage))
            let priceTargets = priceTarget ? priceTarget.price : 50
            return Math.ceil(priceTargets)
        },
        priceTargetFollow() {
            let getPackage = "seeding_by_workers_query_follow_config"
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            let priceTarget = tool.prices.find(price => price.package_name.includes(getPackage))
            let priceTargets = priceTarget ? priceTarget.price : 50
            return Math.ceil(priceTargets)
        }
    },
    watch: {
        site() {
            this.siteUpdate = Object.assign({}, this.site)
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
    },
    methods: {
        async openTarget() {
            this.siteUpdate.config.open_target = !this.open_target
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                let siteHost = document.location.host
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", siteHost)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
