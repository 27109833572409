var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-8 col-12"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row form-group align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-md col-12 text-left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-2"},[_c('button',{class:[
                                            'btn btn-block btn-rounded bold',
                                            _vm.open_target ? 'btn-filter' : 'btn-light'
                                        ],attrs:{"type":"submit"},on:{"click":function($event){return _vm.openTarget()}}},[_vm._v(" Bật ")])]),_c('div',{staticClass:"col-md-6 mt-2"},[_c('button',{class:[
                                            'btn btn-block btn-rounded bold',
                                            !_vm.open_target ? 'btn-filter' : 'btn-light'
                                        ],attrs:{"type":"submit"},on:{"click":function($event){return _vm.openTarget()}}},[_vm._v(" Tắt ")])])])])]),_vm._m(1),(_vm.open_target)?_c('div',[_c('h6',{staticClass:"mb-0 mt-3 font-14 red"},[_vm._v(" Hệ thống sẽ "),_c('span',{staticClass:"bold green"},[_vm._v("thu phí thêm "+_vm._s(_vm.priceTargetLike)+"%")]),_vm._v(" cho "),_c('span',{staticClass:"bold green"},[_vm._v("1 lựa chọn nâng cao Like, Comment, Share.")])]),_c('h6',{staticClass:"mb-0 mt-3 font-14 red"},[_vm._v(" Hệ thống sẽ "),_c('span',{staticClass:"bold green"},[_vm._v("thu phí thêm "+_vm._s(_vm.priceTargetFollow)+"%")]),_vm._v(" cho "),_c('span',{staticClass:"bold green"},[_vm._v("1 lựa chọn nâng cao Follow, Like Page.")])])]):_vm._e()])])]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 col-12"},[_c('h6',{staticClass:"bold mb-0"},[_vm._v("Lọc nâng cao:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-0 mt-3 font-14 red"},[_vm._v(" Nếu bạn "),_c('span',{staticClass:"bold green"},[_vm._v("Bật")]),_vm._v(" thì hệ thống sẽ mở lọc các Target: "),_c('span',{staticClass:"bold green"},[_vm._v("Giới tính, độ tuổi, số bạn bè.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-12"},[_c('div',{staticClass:"card card-red h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{},[_c('h6',[_c('i',{staticClass:"fa fa-info mr-1"}),_vm._v(" Lưu ý: Bạn có thể bật tắt tính năng lọc nâng cao của Facebook tại đây. ")]),_c('h6',[_vm._v(" Hãy Qua cài đặt giá và cài đặt Buff Theo Target % thật chính xác trước khi bật tính năng. ")])])])])])
}]

export { render, staticRenderFns }