<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Tên trang web:</label>
                    <div class="col-sm-8">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Tên trang web"
                            required=""
                            v-model="siteUpdate.site_name"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Tên đơn vị tiền:</label>
                    <div class="col-sm-8">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Tên đơn vị tiền"
                            required=""
                            v-model="siteUpdate.currency"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Mô tả:</label>
                    <div class="col-sm-8">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập mô tả về trang web của bạn"
                            required=""
                            v-model="siteUpdate.site_description"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Keyword:</label>
                    <div class="col-sm-8">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập keyword trang web của bạn"
                            required=""
                            v-model="siteUpdate.site_keyword"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-bold">Email liên hệ:</label>
                    <div class="col-sm-8">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập email liên hệ của bạn"
                            required=""
                            v-model="siteUpdate.email"
                        />
                        <button
                            @click="updateSiteInfo()"
                            class="btn mt-3 form-control bold btn-dark-blue"
                            type="submit"
                        >
                            Lưu thông tin
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-red mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn có thể chỉnh sửa các thông tin của trang.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "site-website",
    components: {},
    props: {
        levelList: Array,
        site: Object
    },
    data() {
        return {
            siteUpdate: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    watch: {
        site() {
            this.siteUpdate = Object.assign({}, this.site)
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
    },
    methods: {
        updateSiteInfo: async function() {
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                let siteHost = document.location.host
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", siteHost)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
