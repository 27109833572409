<template>
    <div>
        <div class="row">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-header bg-dark-blue">
                        <h6 class="bold mb-0 text-center">THANH SỬA GIÁ NHANH</h6>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Chọn Level:</h6>
                            </div>
                            <div class="col-md">
                                <select v-model="chosen_level" class="form-control select-light">
                                    <option v-for="level in levelList" :key="'level' + level.id" :value="level">
                                        {{ level.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Thay đổi giá theo %:</h6>
                            </div>
                            <div class="col-md">
                                <input
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập tỉ lệ"
                                    required=""
                                    v-model="quick_update_ratio"
                                />
                            </div>
                        </div>
                        <div class="col-12 text-right pr-0">
                            <button
                                @click="priceQuickUpdate"
                                class="mt-3 mr-2 mt-md-0 btn bold btn-dark-blue"
                                type="submit"
                            >
                                Tiến hành
                            </button>
                            <button
                                @click.stop.prevent="priceResetAll"
                                class="mt-3 mt-md-0 btn bold btn-red"
                                type="submit"
                            >
                                Reset giá
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card my-3">
                    <div class="card-header bg-dark-blue">
                        <h6 class="bold mb-0 text-center">THANH SỬA GIÁ THỦ CÔNG</h6>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Chọn Level:</h6>
                            </div>
                            <div class="col-md-9">
                                <select v-model="chosen_level" class="form-control select-light">
                                    <option v-for="level in levelList" :key="'level-' + level.id" :value="level">
                                        {{ level.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3 align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Chọn Tool cần sửa giá:</h6>
                            </div>
                            <div class="col-md">
                                <select v-model="selectedTool" class="form-control select-light">
                                    <option v-for="tool in configTools" :key="'selectedTool-' + tool.id" :value="tool">
                                        <!-- Buff Like, Comment, Share Post, Follow, Like Page -->
                                        <template
                                            v-if="tool.name === 'Buff Like, Comment, Share Post, Follow, Like Page'"
                                        >
                                            Facebook Seeding
                                        </template>
                                        <template v-else>
                                            {{ tool.name }}
                                        </template>
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-auto">
                                <span v-if="selectedTool" class="mr-2" v-html="selectedTool.hide_html"></span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 text-right">
                                <span class="bold mr-1">Site gốc</span>
                                <span v-if="selectedTool" class="mr-2" v-html="selectedTool.status_html"></span>
                                <button
                                    v-if="selectedTool && selectedTool.is_hidden"
                                    @click="toggleTool(selectedTool)"
                                    type="button"
                                    :class="['btn btn-dark-blue']"
                                >
                                    <i class="fa fa-eye"></i>
                                    Click để Bật Tool
                                </button>
                                <button v-else type="button" @click="toggleTool(selectedTool)" :class="['btn btn-red']">
                                    <i class="fa fa-eye-slash"></i>
                                    Click để Ẩn Tool
                                </button>
                            </div>
                        </div>
                        <sitepricesdetail :tools="tools" :selectedTool="selectedTool"></sitepricesdetail>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card card-red mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Hướng dẫn
                            </h6>
                            <li>Bạn có thể chỉnh sửa giá, bật/tắt các dịch vụ theo từng level của khách tại đây.</li>
                            <li>
                                Bạn có thể điều chỉnh giá của tất cả các gói theo tỷ lệ bằng chức năng [Thay đổi giá
                                theo %].
                            </li>
                            <li>
                                Vd: Để x2 giá của tất cả các gói, nhập 200%. Để giảm giá 50% tất cả các gói, nhập 50%.
                            </li>
                            <li>Hệ thống sẽ tự động làm tròn chữ số thập phân.</li>
                            <li>
                                Để cài đặt giá có số lẻ: vd: 1.5 {{ currency }}, 2.5 {{ currency }},.. vui lòng dùng
                                chức năng [Sửa giá thủ công]
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { priceUpdate, priceReset, getListLevels, getToolAdmin, showTool, hideTool } from "../../../api/admin"
import { catchError } from "../../../helpers"
import sitepricesdetail from "./site-prices-detail"
import menus from "@/config/menu"
export default {
    name: "site-prices-vm",
    components: {
        sitepricesdetail
    },
    data() {
        return {
            menus: menus,
            chosen_level: "",
            quick_update_ratio: "100%",
            levelList: [],
            configTools: [],
            tools: [],
            selectedTool: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        chosenTool() {
            return this.$store.state.tools.chosenTool
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    watch: {
        selectedTool(value) {
            this.$store.commit("STORE_SET_CHOOSE_TOOLS", value)
        },
        tools: {
            handler: function() {
                this.toolConfigMenu()
                let toolSelect = this.chosenTool && this.chosenTool.id ? this.chosenTool : this.configTools[0]
                let toolCurrent = this.configTools.find(tool => tool.id == toolSelect.id)
                this.selectedTool = toolCurrent
                this.$store.commit("STORE_SET_CHOOSE_TOOLS", toolCurrent)
            },
            deep: true
        },
        chosen_level() {
            this.getToolAdmin()
        },
        reload() {
            this.getToolAdmin()
            this.toolConfigMenu()
            this.getListLevels()
        }
    },
    async created() {
        await this.getListLevels()
        let userLevel = this.user.levels.find(lv => {
            return lv.site_id === this.site.id
        })
        for (let level of this.levelList) {
            if (level.id === userLevel.id) {
                this.chosen_level = Object.assign({}, level)
            }
        }
    },
    methods: {
        toggleTool: async function(tool) {
            if (tool.is_hidden) {
                let data = await showTool(tool.id)
                if (data.status === 200 && data.success) {
                    await this.getToolAdmin()
                    this.$toastr.success("Đã bật: " + tool.notes)
                } else this.$toastr.erorr("Lỗi, không thể ẩn: " + tool.notes)
            } else {
                let data = await hideTool(tool.id)
                if (data.status === 200 && data.success) {
                    await this.getToolAdmin()
                    this.$toastr.success("Đã ẩn: " + tool.notes)
                } else this.$toastr.erorr("Lỗi, không thể ẩn: " + tool.notes)
            }
        },
        getToolAdmin: async function() {
            if (this.chosen_level) {
                this.tools = await getToolAdmin(this.chosen_level.id).then(data => data.data)
                this.$store.dispatch("GET_TOOLS")
            }
        },
        getListLevels: async function() {
            let data = await getListLevels()
            this.levelList = data.data
        },
        priceQuickUpdate: async function() {
            let countSuccess = 0
            let countError = 0
            let ratio = 0
            if (this.quick_update_ratio.includes("%")) {
                ratio = Number(this.quick_update_ratio.replace("%", ""))
                if (!ratio || ratio < 0) {
                    this.$swal("Lỗi", "Vui lòng nhập chính xác tỷ lệ % để tiến hành chỉnh giá nhanh", "error")
                    return
                }
            } else {
                this.$swal("Lỗi", "Vui lòng nhập chính xác tỷ lệ % để tiến hành chỉnh giá nhanh", "error")
                return
            }
            let _this = this
            // this.$swal.fire({
            //     type: "info",
            //     title: "Xác nhận",
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //     confirmButtonText: "Tiến Hành",
            //     cancelButtonText: "Hủy bỏ",
            //     text: "Bạn có chắc chắn muốn thay đổi tất cả các giá hiện tại không?",
            //     showCancelButton: true,
            //     showLoaderOnConfirm: true,
            //     reverseButtons: true,
            //     preConfirm: async () => {
            //         for (let i = 0; i < _this.tools.length; i++) {
            //             if (_this.tools[i].prices) {
            //                 let prices = _this.tools[i].prices
            //                 for (let j = 0; j < prices.length; j++) {
            //                     let data = await priceUpdate(prices[j].id, { price: Math.round((prices[j].price * ratio) / 100) })
            //                     data.status === 200 ? countSuccess++ : countError++
            //                 }
            //             }
            //         }
            //         let errorText = countError ? (errorText += "\nLỗi: " + countError + " gói") : null
            //         this.$swal("Hoàn Thành", "Cập nhật thành công " + countSuccess + " gói" + errorText, "success")
            //         _this.$store.commit("APP_RELOAD")
            //         _this.$store.dispatch("GET_TOOLS")
            //     },
            //     allowOutsideClick: () => !this.$swal.isLoading()
            // })
            this.$swal
                .fire({
                    type: "info",
                    title: "Xác nhận",
                    text: "Bạn có chắc chắn muốn thay đổi tất cả các giá hiện tại không?",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Bỏ Qua",
                    confirmButtonText: "Xác Nhận",
                    showLoaderOnConfirm: true,
                    reverseButtons: true,
                    preConfirm: async () => {
                        for (let i = 0; i < _this.tools.length; i++) {
                            if (_this.tools[i].prices) {
                                let prices = _this.tools[i].prices
                                for (let j = 0; j < prices.length; j++) {
                                    let data = await priceUpdate(prices[j].id, {
                                        price: Math.round((prices[j].price * ratio) / 100)
                                    })
                                    data.status === 200 ? countSuccess++ : countError++
                                }
                            }
                        }
                        let errorText = countError ? (errorText += "\nLỗi: " + countError + " gói") : null
                        this.$swal("Hoàn Thành", "Cập nhật thành công " + countSuccess + " gói" + errorText, "success")
                        _this.$store.commit("APP_RELOAD")
                        _this.$store.dispatch("GET_TOOLS")
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                })
                .then(result => {
                    if (result.value) {
                        this.$swal.fire("Thành công", "Cập nhật giá theo % thành công!", "success")
                    }
                })
        },
        priceResetAll: async function() {
            let that = this
            this.$swal({
                title: "Xác nhận",
                text:
                    "Bạn có chắc chắn muốn reset lại tất cả giá của level " +
                    that.chosen_level.name +
                    "? Sau khi reset sẽ không khôi phục lại được.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Có",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(async result => {
                if (result.value) {
                    let postData = {
                        site_id: that.site.id,
                        level: that.chosen_level.name
                    }
                    let data = await priceReset(postData)
                    if (data.status === 200 && data.success) {
                        this.$swal("Thành Công", "Reset giá thành công", "success")
                        that.$store.commit("APP_RELOAD")
                        that.$store.dispatch("GET_TOOLS")
                    } else {
                        this.$swal("Lỗi", catchError(data), "error")
                    }
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ thao tác này", "info")
                }
            })
        },
        toolConfigMenu: function() {
            let tableTool = []
            menus.forEach(item => {
                if (item.tool_alias) {
                    let tool = this.tools.find(tool => {
                        return tool.tool_alias === item.tool_alias
                    })
                    if (tool && tableTool.indexOf(tool) < 0) {
                        tableTool.push(tool)
                    }
                }
                if (item.childs) {
                    item.childs.forEach(child => {
                        if (child.tool_alias) {
                            let tool = this.tools.find(tool => {
                                return tool.tool_alias === child.tool_alias
                            })
                            if (tool && tableTool.indexOf(tool) < 0) {
                                tableTool.push(tool)
                            }
                        }
                    })
                }
            })

            this.configTools = tableTool.map((tool, index) => {
                if (tool.notes === "Buff Like, Comment, Share Post") {
                    tool.notes = "Buff Like, Comment, Share Post, Follow, Like Page"
                    tool.name = "Buff Like, Comment, Share Post, Follow, Like Page"
                }
                if (tool.notes === "Tăng Like Bài Viết Instagram") {
                    tool.notes = "Buff Like, Sub, Comment Instagram"
                }
                let price = tool.prices[0] ? tool.prices[0].price : 0
                tool.index = index + 1
                tool.hide_html = tool.is_hidden
                    ? '<span class="badge badge-danger"><i class="fa fa-eye-slash mr-1"></i>Đang ẩn</span>'
                    : '<span class="badge badge-info"><i class="fa fa-eye mr-1"></i>Đang bật</span>'
                tool.status_html =
                    tool.status === 1
                        ? '<span class="badge badge-success">Đang chạy</span>'
                        : tool.status === 0
                        ? '<span class="badge badge-warning" style="min-width: 65px">Bảo trì</span>'
                        : '<span class="badge badge-danger" style="min-width: 65px">Ẩn</span>'
                tool.price_html =
                    '<div class="form-control text-muted text-center" style="background-color: #e9ecef; opacity:0.8; min-width: 120px; display:inline-block">' +
                    price.toLocaleString("it-IT") +
                    " " +
                    this.currency +
                    "</div>"
                return tool
            })
        }
    }
}
</script>
